import React from 'react';
import { tr } from './text';
import { Page } from '../../components/Page';
import { ButtonGrid } from '../../components/ButtonGrid';


export const SubjectMenuItems = [
  {
    supTitle: tr("text-menu-title-science"),
    items: [
      {icon: <></>, text: tr("text-menu-item-maths"), href: "/subject/maths"},
    ]
  },
  {
    supTitle: tr("text-menu-title-langue"),
    items: [
      {icon: <></>, text: tr("text-menu-item-french"), href: "/subject/french"},
      //{icon: <></>, text: tr("Anglais"), href: "/subject/english"},
    ]
  }
];


export const SubjectPathItems = [
  {title: tr('text-menu-path-accueil'), href: '/'},
  {title: tr('text-menu-path-subject'), href: '/subject'}
];


export const SubjectMenuButtons = [
  {text: tr("text-menu-button-maths"), href: "/subject/maths"},
  {text: tr("text-menu-button-french"), href: "/subject/french"},
  //{text: tr("Anglais"), href: "/subject/english"},
];


export const SubjectPage = () => {
  return (
    <Page
      menu={SubjectMenuItems}
      path={SubjectPathItems}
    >
      <ButtonGrid
        buttons={SubjectMenuButtons}
      />
    </Page>
  );
};
