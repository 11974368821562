import React, { useState, useEffect } from "react";
import { tr } from "./text";
import { MathsUndefinedPage } from "../../../MathsUndefinedPage";

import Algebrite from 'algebrite';
import 'katex/dist/katex.min.css';
import { Link } from "react-router-dom";

import { AutoResizeTextarea } from "../../../../../../components/AutoResizeTextarea";
import { MathExpressionLatex } from "../../../../../../components/MathExpressionLatex";
import { ProcessButton } from "../../../../../../components/ProcessButton";


export const FactoriseNombrePremierPage = () => {
  const [expr, setExpr] = useState("");
  const [exprToProcess, setExprToProcess] = useState("");
  const [result, setResult] = useState("");
  const [isResultCopied, setIsResultCopied] = useState(false);

  useEffect(() => {
    if (exprToProcess === '') {
      setResult('');
      return;
    }

    try {
      let devReduce = Algebrite.simplify(exprToProcess).toString();

      // verify exprToProcess is an integer
      if (!/^\d+$/.test(devReduce)) {
        setResult(tr('text-it-is-not-an-integer'));
        return;
      }

      devReduce = Algebrite.factor(devReduce).toString();

      if (devReduce === 'undefined' || devReduce === 'nil') {
        devReduce = '';
      }

      setResult(devReduce);
    } catch (error) {
      setResult(exprToProcess);
    }
  }, [exprToProcess]);

  return (
    <MathsUndefinedPage>
      <h1
        className="text-3xl font-bold text-center text-gray-800 my-6"
      >{tr('text-page-title')}</h1>
      <div
        className="text-center mb-4"
      >
        <Link
          to="/subject/maths/doc-syms"
          className="text-blue-500 hover:underline"
          target="_blank"
        >{tr('text-doc-syms')}</Link>
      </div>
      <AutoResizeTextarea
        placeHolder={tr('text-enter-integer-to-factorize')}
        onChange={(value) => { setExpr(value); setExprToProcess(""); setResult(""); }}
        className="mb-1"
      />
      <MathExpressionLatex
        expression={expr}
      />
      <ProcessButton
        onClick={() => setExprToProcess(expr)}
        label={tr('text-factorize-in-prime-number')}
      />
      <div
        className="relative flex"
      >
        <MathExpressionLatex
          className="relative"
          expression={result}
        />
        {!!result && result.length > 0 ?
          <svg
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
            className="size-6 cursor-pointer absolute right-2 bottom-4"
            onClick={() => {navigator.clipboard.writeText(result); setIsResultCopied(true); setTimeout(() => setIsResultCopied(false), 3000);}}
          >
            <title>{tr('text-copy-result')}</title>
            <path
              strokeLinecap="round" strokeLinejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
            />
          </svg>
        : ''}
      </div>
      {isResultCopied ?
        <div
          className="w-full text-right text-green-500"
        >{tr('text-result-copied')}</div>
      : ''}
      <div className="w-full text-center">{tr('text-the-calculation-can-take-some-time')}</div>
    </MathsUndefinedPage>
  );
};
