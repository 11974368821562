const text_data = {
  'text-menu-accueil' : {
    'fr': 'Accueil',
    'en': 'Home',
  },
  'text-menu-maths' : {
    'fr': 'Maths',
    'en': 'Maths',
  },
  'text-menu-francais' : {
    'fr': 'Français',
    'en': 'French',
  },
  'text-menu-anglais' : {
    'fr': 'Anglais',
    'en': 'English',
  },
  'text-menu-cgu' : {
    'fr': 'CGU',
    'en': 'TOS',
  },
  'text-menu-politique' : {
    'fr': 'Politique',
    'en': 'Privacy',
  },
}

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};

