import React from "react";
import { tr } from "./text";
import { Link, useLocation } from "react-router-dom";


export const BodyMenu = ({ items }) => {

  const defaultItems = [
    {
      supTitle: "Title 1",
      items: [
        {icon: <></>, text: "Sub title 1", href: "/subject"},
        {icon: <></>, text: "Sub title 2", href: "/level"},
      ]
    },
    {
      supTitle: "Title 2",
      items: [
        {icon: <></>, text: "Sub title 1", href: "/subject/maths"},
        {icon: <></>, text: "Sub title 2", href: "/subject/french"},
        {icon: <></>, text: "Sub title 3", href: "/subject/english"},
      ]
    },
    {
      supTitle: "Title 3",
      items: [
        {icon: <></>, text: "Sub title 1", href: "/level/second"},
        {icon: <></>, text: "Sub title 2", href: "/level/first"},
        {icon: <></>, text: "Sub title 3", href: "/level/last"},
      ]
    },
  ];

  const itemsToUse = (!!items ? items : defaultItems);
  const location = useLocation();

  return (
    <div
      className="p-2 min-w-60 w-60 max-w-60 min-h-full border-r border-r-gray-300 select-none"
    >
      <div
        className="text-xl font-bold w-full text-center mr-2 mt-2 mb-4 py-2 px-1 rounded-md bg-blue-200"
      >{tr('text-menu-sup-title')}</div>
      <div>
        {itemsToUse.map((item, index) => (
          <div
            key={index}
            className="mb-4"
          >
            <div
              className="text-base font-semibold my-2"
            >{item.supTitle}</div>
            <div
              className=""
            >
              {item.items.map((subItem, subIndex) => (
                <Link
                  key={subIndex}
                  to={subItem.href}
                  className="w-full h-full"
                >
                  <div
                    className={`
                      pl-3 text-base font-normal 
                      border-l-2 
                      cursor-pointer w-full
                      ${location.pathname === subItem.href ? "text-blue-700 border-l-blue-500" : " text-gray-500 border-l-gray-400 hover:text-orange-400 hover:border-l-orange-300"}
                    `}
                  >{subItem.text}</div>
                </Link>
              ))}
            </div>
          </div>
        ))
        }
      </div>
    </div>
  );
};
