import React from 'react';
import { BlockMath } from 'react-katex';
import { tr } from './text';
import { create, all } from 'mathjs';

const customIntegrals = {
  integral: function (f, x, a, b) {
    return 1;
  }
}

customIntegrals.integral.toTex = function (node, options) {
  return '\\int_{' + (!!node.args[2] ? node.args[2] : '') + '}^{' + (!!node.args[3] ? node.args[3] : '') + '} ' + node.args[0].toTex(options) + ' d' + node.args[1].toTex(options);
}

const math = create(all);
math.import(customIntegrals);




export const algebriteToLatex = (algebriteExpression) => {
  let latexExpression = '';

  if (!latexExpression) {
    try {
      latexExpression = math.parse(algebriteExpression).toTex();
    } catch (error) {
      latexExpression = tr('text-erreur-de-syntaxe');
    }
  }

  return latexExpression;
};


export const MathExpressionLatex = ({ expression, className }) => {
  let latexExpression = !!expression ? algebriteToLatex(expression) : '';

  return (
    <div
      className={`bg-gray-400 bg-opacity-10 min-w-8 w-full py-1 my-1 rounded-lg ${className} max-w-full overflow-y-auto overflow-x-auto break-words`}
    >
      {!!latexExpression && latexExpression !== tr('text-erreur-de-syntaxe') && latexExpression !== 'undefined' && latexExpression !== 'nil' ?
        <BlockMath
          math={latexExpression}
          errorColor="#cc0000"
        />
      :
        <div
          className="text-red-500 text-center py-2"
        >{latexExpression}</div>
      }
    </div>
  );
};
