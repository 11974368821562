import React from "react";
import { tr } from "./text";
import { MathsUndefinedPage, MathsMenuItems } from "./MathsUndefinedPage";
import { Link } from "react-router-dom";


export const MathsPage = () => {
  return (
    <MathsUndefinedPage>
      <h1 className="text-3xl font-bold text-center text-gray-800 mt-6 mb-4">
        {tr('text-menu-title-welcome')}
      </h1>
      <p className="text-lg text-gray-700 mb-6 px-2 text-justify">
        {tr('text-menu-descr-welcome')}
      </p>

      <hr className="my-6 border-t-2" />

      <h2
        className="text-2xl font-bold text-center text-gray-800 my-4"
      >{tr('text-menu-title-index')}</h2>
      <div
        className="mb-64"
      >
        {MathsMenuItems.map((item, index) => (
          <div key={index}>
            <h2 className="text-xl font-bold text-gray-800 mt-6 mb-2 mx-4">
              {`${index + 1}. ${item.supTitle}`}
            </h2>
            <ul className="text-lg text-gray-700 mb-8 text-justify mx-4">
              {item.items.map((subItem, subIndex) => (
                <li key={subIndex}>
                  <Link
                    to={subItem.href}
                    className="text-blue-500 hover:underline"
                  >{`${index + 1}.${subIndex + 1}. ${subItem.text}`}</Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </MathsUndefinedPage>
  );
};
