const text_data = {
  "text-it-is-not-an-integer": {
    "fr": "Ce n'est pas un entier",
    "en": "It is not an integer",
  },
  "text-page-title": {
    "fr": "Factorisation en nombre premier",
    "en": "Factorization in prime number",
  },
  "text-doc-syms": {
    "fr": "Documentation de la syntaxe et des symboles",
    "en": "Documentation of the syntax and symbols",
  },
  "text-enter-integer-to-factorize": {
    "fr": "Entrez un entier à factoriser en nombre premier",
    "en": "Enter an integer to factorize in prime number",
  },
  "text-factorize-in-prime-number": {
    "fr": "Factoriser en nombre premier",
    "en": "Factorize in prime number",
  },
  "text-copy-result": {
    "fr": "Copier le résultat",
    "en": "Copy the result",
  },
  "text-result-copied": {
    "fr": "Résultat copié",
    "en": "Result copied",
  },
  "text-the-calculation-can-take-some-time": {
    "fr": "Le calcul peut prendre du temps",
    "en": "The calculation can take some time",
  },
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};
