import React from "react";

import { PageComponent } from "../PageComponent";
import { HeaderItem } from "./Item";
import { Link, useLocation } from "react-router-dom";

import { useStore } from "../../../store";

import { tr } from "./text";


export const Header = ({ onBarsClicked, className }) => {
  const { selectedHeaderItem, setSelectedHeaderItem} = useStore();
  const [isBodyMenuShow, setIsBodyMenuShow] = React.useState(true);

  const itemsMiddle = [
    { title: tr("text-menu-accueil"), href: "/" },
    { title: tr("text-menu-maths"), href: "/subject/maths" },
    { title: tr("text-menu-francais"), href: "/subject/french" },
    //{ title: tr("Anglais"), href: "/subject/english" },
  ];

  const itemsRight = [
    // { title: tr("Contact"), href: "/contact" },
    { title: tr("text-menu-cgu"), href: "/terms-of-service" },
    { title: tr("text-menu-politique"), href: "/privacy-policy" },
  ];

  const catItems = [...itemsMiddle, ...itemsRight];

  //  current path
  const location = useLocation();

  const path = location.pathname;
  for (let i = 0; i < catItems.length; i++) {
    if (catItems[i].href.startsWith(path)) {
      if (selectedHeaderItem !== i)
      { setSelectedHeaderItem(i);}
      break;
    }
  }

  //  If browser langage is french, we set the default language to french
  //  Otherwise, we set the default language to english
  if (localStorage.getItem("lang") !== "fr") {
    localStorage.setItem("lang", "en");
  }

  return (
    <PageComponent
      className={`flex justify-between space-x-1 select-none min-h-full ${className}`}
    >
      <div className="flex pl-6 items-center">
        <div
          className={`flex items-center justify-center w-8 h-8 hover:bg-orange-200 rounded-md`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
            className={`size-6 cursor-pointer`}
            onClick={() => { setIsBodyMenuShow(!isBodyMenuShow); onBarsClicked(); }}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </div>
        <div className="flex pl-4 items-center font-extrabold text-xl cursor-default"
        >
          xili<span className="text-red-700 text-3xl">A</span>u
        </div>
      </div>
      <div
        className="flex justify-center"
      >
        {itemsMiddle.map((item, index) => (
          <Link
            key={index}
            to={item.href}
          >
            <HeaderItem
              key={index}
              title={item.title}
              selected={selectedHeaderItem === index}
              onClick={() => setSelectedHeaderItem(index)}
            />
          </Link>
        ))}
      </div>
      <div
        className="flex justify-center pr-6"
      >
        {itemsRight.map((item, index) => ( // desactiver pour le moment
          <Link
              key={index}
              to={item.href}
              target={"_blank"}
            >
            <HeaderItem
              key={index}
              title={item.title}
              selected={selectedHeaderItem === index + itemsMiddle.length}
              onClick={() => {
                //setSelectedHeaderItem(index + itemsMiddle.length);
              }}
            />
          </Link>
        ))}

        <select
          className="rounded-md bg-transparent ml-3"
          onChange={(e) => {
            const lang = e.target.value;
            localStorage.setItem("lang", lang);
            window.location.reload();
          }}
          value={localStorage.getItem("lang")}
        >
          <option value="fr">🇫🇷 Français</option>
          <option value="en">🇬🇧 English</option>
        </select>
      </div>
    </PageComponent>
  );
};
