const text_data = {
  "text-menu-sup-title": {
    "fr": "Menu",
    "en": "Menu",
  },
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};
