import React, { forwardRef } from "react";


export const PageComponent = forwardRef(({ className, children }, ref) => {
  return (
    <div
      className={`${className}`}
    >{ children }</div>
  );
});
