import React from "react";


export const ProcessButton = ({ label, onClick, className }) => {
  return (
    <div
      className={`text-center  border-2 border-blue-300 bg-blue-200 hover:border-blue-400 hover:bg-blue-300 py-2 rounded-full my-2 cursor-pointer select-none ${className}`}
      onClick={onClick}
    >{label}</div>
  );
};
