import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from 'react-transition-group';

import { PageComponent } from "../PageComponent";
import { BodyMenu } from "./Menu";
import { AdvSection } from "./AdvSection";

import { PathComponent } from "../../PathComponent";


export const Body = ({ path, children, showBodyMenu, menu }) => {
  const parentRef = useRef(null);
  const childRef = useRef(null);
  const [childWidth, setChildWidth] = useState('100%');

  useEffect(() => {
    const adjustChildWidth = () => {
      if (parentRef?.current && childRef?.current) {
        setChildWidth(`${parentRef.current.offsetWidth - 500}px`);
      }
    };

    adjustChildWidth();

    window.addEventListener('resize', adjustChildWidth);
    return () => window.removeEventListener('resize', adjustChildWidth);
  }, []);

  return (
    <PageComponent ref={parentRef} className={`flex flex-row w-full min-h-screen`}>
      <CSSTransition
        in={showBodyMenu}
        timeout={100}
        classNames="fade"
        unmountOnExit
      >
        <BodyMenu items={menu} />
      </CSSTransition>
      <div
        className={`px-2 scroll-x-auto overflow-x-auto flex flex-col w-full`}
        ref={childRef}
      >
        <PathComponent
          path={path}
          className={"my-3"}
        />
        <div
          className="w-full mt-2"
        >{ children }</div>
      </div>
      <AdvSection />
    </PageComponent>
  );
};
