export const text_data = {
  "text-page-title": {
    "fr": "Factoriser une expression",
    "en": "Factorize an expression",
  },
  "text-doc-syms": {
    "fr": "Documentation de la syntaxe et des symboles",
    "en": "Documentation of the syntax and symbols",
  },
  "text-enter-expr": {
    "fr": "Entrez une expression à factoriser",
    "en": "Enter an expression to factorize",
  },
  "text-factorize": {
    "fr": "Factoriser",
    "en": "Factorize",
  },
  "text-copy-result": {
    "fr": "Copier le résultat",
    "en": "Copy the result",
  },
  "text-result-copied": {
    "fr": "Résultat copié",
    "en": "Result copied",
  },
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};
