import React from 'react';
import { Page } from '../../../../components/Page';

import { tr } from './text';


export const MathsMenuItemsSeconde = [
  {
    supTitle: tr("text-menu-title-calcul-literal"),
    items: [
      {icon: <></>, text: tr("text-menu-item-expand-expr"), href: "/subject/maths/expand-expr"},
      {icon: <></>, text: tr("text-menu-item-factor-expr"), href: "/subject/maths/factor-expr"},
      {icon: <></>, text: tr("text-menu-item-verify-eq-expr"), href: "/subject/maths/verify-eq-expr"},
      {icon: <></>, text: tr("text-menu-item-eval-expr"), href: "/subject/maths/eval-expr"},
    ]
  },
  {
    supTitle: tr("text-menu-title-nombre-fraction"),
    items: [
      {icon: <></>, text: tr("text-menu-item-same-denom"), href: "/subject/maths/same-denom"},
      {icon: <></>, text: tr("text-menu-item-prime-factors"), href: "/subject/maths/prime-factors"},
      {icon: <></>, text: tr("text-menu-item-gcd"), href: "/subject/maths/gcd"},
      {icon: <></>, text: tr("text-menu-item-lcm"), href: "/subject/maths/lcm"},
    ]
  }
];


export const MathsMenuItemsPremiere = [
];


export const MathsMenuItemsTerminale = [
];


export const MathsMenuItems = [...MathsMenuItemsSeconde, ...MathsMenuItemsPremiere, ...MathsMenuItemsTerminale];


export const MathsPathItems = [
  {title: tr('text-path-acceuil'), href: '/'},
  {title: tr('text-path-matiere'), href: '/subject'},
  {title: tr('text-path-maths'), href: '/subject/maths'}
];


export const MathsUndefinedPage = ({ children }) => {
  return (
    <Page
      menu={MathsMenuItems}
      path={MathsPathItems}
    >{children}</Page>
  );
};
