import React from "react";


export const HeaderItem = ({ title, selected, className, onClick }) => {
  return (
    <div
      className={`
        flex items-center justify-center pt-1
        border-b-4 ${ selected ? 'border-blue-600' : 'border-transparent hover:border-orange-300' }
      `}
      onClick={onClick}
    >
      <div className={`
        h-full flex items-center justify-center cursor-pointer
        px-3 py-2 font-semibold
        ${className}
        `}
      >{ title }</div>
    </div>
  );
};
