const text_data = {
  "text-page-title": {
    "fr": "Bienvenue dans la section français",
    "en": "Welcome to the French section"
  },
  "text-page-content": {
    "fr": "Cette section est en cours de développement. Merci de votre patience.",
    "en": "This section is under development. Thank you for your patience."
  },
  "text-index": {
    "fr": "Index :",
    "en": "Index:"
  },
};

export const tr = (key) => {
  return text_data[key][localStorage["lang"]];
};
