import React from 'react';

const symbols = [
  {
    category: "Opérateurs de Base",
    items: [
      { symbol: "+", meaning: "Addition", example: "2 + 3" },
      { symbol: "-", meaning: "Soustraction", example: "5 - 2" },
      { symbol: "*", meaning: "Multiplication", example: "4 * 3" },
      { symbol: "/", meaning: "Division", example: "8 / 2" },
      { symbol: "^", meaning: "Puissance (exponentiation)", example: "2 ^ 3" },
      { symbol: "%", meaning: "Modulo (reste de la division)", example: "7 % 3" },
      { symbol: "()", meaning: "Parenthèses pour regrouper des termes", example: "(2 + 3) * 4" },
    ]
  },
  {
    category: "Fonctions Mathématiques",
    items: [
      { symbol: "sqrt(x)", meaning: "Racine carrée de `x`", example: "sqrt(16)" },
      { symbol: "abs(x)", meaning: "Valeur absolue de `x`", example: "abs(-5)" },
      { symbol: "log(x)", meaning: "Logarithme naturel (base `e`)", example: "log(10)" },
      { symbol: "log10(x)", meaning: "Logarithme en base 10", example: "log10(100)" },
      { symbol: "exp(x)", meaning: "Exponentielle de `x` (`e^x`)", example: "exp(1)" },
      { symbol: "sin(x)", meaning: "Sinus de `x` (en radians)", example: "sin(pi / 2)" },
      { symbol: "cos(x)", meaning: "Cosinus de `x` (en radians)", example: "cos(0)" },
      { symbol: "tan(x)", meaning: "Tangente de `x` (en radians)", example: "tan(pi / 4)" },
      { symbol: "gcd(a, b)", meaning: "Plus grand commun diviseur de `a` et `b`", example: "gcd(8, 12)" },
    ]
  },
  {
    category: "Fonctions de Statistiques",
    items: [
      { symbol: "sum([a, b, c])", meaning: "Somme des éléments", example: "sum([1, 2, 3])" },
      { symbol: "mean([a, b, c])", meaning: "Moyenne des éléments", example: "mean([1, 2, 3])" },
      { symbol: "median([a, b, c])", meaning: "Médiane des éléments", example: "median([1, 3, 2])" },
      { symbol: "min([a, b, c])", meaning: "Minimum des éléments", example: "min([1, 2, 3])" },
      { symbol: "max([a, b, c])", meaning: "Maximum des éléments", example: "max([1, 2, 3])" },
    ]
  },
  {
    category: "Fonctions de Calcul",
    items: [
      { symbol: "derivative(f, x)", meaning: "Dérivée de `f` par rapport à `x`", example: "derivative('x^2', 'x')" },
      { symbol: "integral(f, x)", meaning: "Intégrale de `f` par rapport à `x`", example: "integral('x^2', 'x')" },
      { symbol: "integral(f, x, a, b)", meaning: "Intégrale de `f` par rapport à `x`", example: "integral('x^2', 'x', 1, 2)" },
      { symbol: "integral(f, x, a)", meaning: "Intégrale de `f` par rapport à `x`", example: "integral('x^2', 'x', 1)" },
      { symbol: "diff([a, b, c])", meaning: "Différences successives", example: "diff([1, 2, 4])" },
      { symbol: "factor(x)", meaning: "Factorisation de `x`", example: "factor('x^2 - 4')" },
      { symbol: "expand(x)", meaning: "Développement de `x`", example: "expand('(x - 2) * (x + 2)')" },
    ]
  },
  {
    category: "Constantes Mathématiques",
    items: [
      { symbol: "pi", meaning: "Constante π", example: "pi" },
      { symbol: "e", meaning: "Constante e (nombre d'Euler)", example: "e" },
      { symbol: "i", meaning: "Unité imaginaire", example: "i" },
      { symbol: "Infinity", meaning: "Infini", example: "Infinity" },
    ]
  },
  {
    category: "Opérations sur les Matrices",
    items: [
      { symbol: "matrix([[a, b], [c, d]])", meaning: "Crée une matrice", example: "matrix([[1, 2], [3, 4]])" },
      { symbol: "det(A)", meaning: "Déterminant de la matrice `A`", example: "det([[1, 2], [3, 4]])" },
      { symbol: "inv(A)", meaning: "Inverse de la matrice `A`", example: "inv([[1, 2], [3, 4]])" },
      { symbol: "transpose(A)", meaning: "Transposée de la matrice `A`", example: "transpose([[1, 2], [3, 4]])" },
    ]
  },
  {
    category: "Comparaison",
    items: [
      { symbol: "==", meaning: "Égal à", example: "2 == 2" },
      { symbol: "!=", meaning: "Différent de", example: "2 != 3" },
      { symbol: "<", meaning: "Inférieur à", example: "2 < 3" },
      { symbol: "<=", meaning: "Inférieur ou égal à", example: "2 <= 2" },
      { symbol: ">", meaning: "Supérieur à", example: "3 > 2" },
      { symbol: ">=", meaning: "Supérieur ou égal à", example: "3 >= 3" },
    ]
  }
];

export const DocSymsPageFr = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-6">Documentation des Symboles</h1>
      {symbols.map((section, index) => (
        <div key={index} className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{section.category}</h2>
          <table className="table-auto w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-4 py-2 text-left">Symbole</th>
                <th className="px-4 py-2 text-left">Signification</th>
                <th className="px-4 py-2 text-left">Exemple</th>
              </tr>
            </thead>
            <tbody>
              {section.items.map((item, idx) => (
                <tr key={idx} className="border-b">
                  <td className="px-4 py-2">{item.symbol}</td>
                  <td className="px-4 py-2">{item.meaning}</td>
                  <td className="px-4 py-2">{item.example}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};
