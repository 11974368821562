const text_data = {
  "text-menu-title-science": {
    "fr": "Science",
    "en": "Science",
  },
  "text-menu-item-maths": {
    "fr": "Mathématiques",
    "en": "Maths",
  },

  "text-menu-title-langue": {
    "fr": "Langue",
    "en": "Language",
  },
  "text-menu-item-french": {
    "fr": "Français",
    "en": "French",
  },

  "text-menu-path-accueil": {
    "fr": "Accueil",
    "en": "Home",
  },
  "text-menu-path-subject": {
    "fr": "Matière",
    "en": "Subject",
  },

  "text-menu-button-maths": {
    "fr": "Mathématiques",
    "en": "Maths",
  },
  "text-menu-button-french": {
    "fr": "Français",
    "en": "French",
  },
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};
