const text_data = {
  "text-path-accueil": {
    "fr": "Accueil",
    "en": "Home"
  },
  "text-path-matiere": {
    "fr": "Matière",
    "en": "Subject"
  },
  "text-path-francais": {
    "fr": "Français",
    "en": "French"
  },
};

export const tr = (key) => {
  return text_data[key][localStorage["lang"]];
};
