import React from "react";
import { Link } from "react-router-dom";
import { tr } from "../../utility/translation";


export const ButtonGrid = ({
  buttons,
  className,
}) => {

  const defaultButtons = [
    {text: tr("Item 1"), href: "/item1"},
    {text: tr("Item 2"), href: "/item2"},
    {text: tr("Item 3"), href: "/item3"},
    {text: tr("Item 4"), href: "/item4"},
    {text: tr("Item 5"), href: "/item5"},
    {text: tr("Item 6"), href: "/item6"},
    {text: tr("Item 7"), href: "/item7"},
    {text: tr("Item 8"), href: "/item8"},
  ];

  const selectedButtons = !!buttons ? buttons : defaultButtons;

  return (
    <div className={`flex flex-wrap w-full min-h-64 p-2 py-8 justify-center items-center gap-8 bg-gray-400 bg-opacity-10 rounded-3xl ${className}`}>
      {selectedButtons.map((button, index) => (
        <Link
          key={index}
          to={button.href}
          className=""
        >
          <div
            className={`
              bg-opacity-70 py-4 w-56 rounded-2xl text-center font-bold text-xl
              border-2 border-blue-400 bg-gradient-to-t from-blue-200 via-blue-100 to-blue-200
              hover:border-orange-400 hover:from-orange-200 hover:via-orange-100 hover:to-orange-200
            `}
          >{button.text}</div>
        </Link>
      ))}
    </div>
  );
};
