import React from "react";

import { PrivacyPolicyFr } from "./lang/fr";
import { PrivacyPolicyEn } from "./lang/en";


export const PrivacyPolicy = () => {
  const lang = localStorage.getItem("lang") || "en";

  return (
    lang === "fr" ?
      <PrivacyPolicyFr />
    :
      <PrivacyPolicyEn />
  );
};
