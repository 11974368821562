import React from 'react';

export const PrivacyPolicyEn = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        Last updated: August 31, 2024
      </p>
      <p className="mb-6">
        This privacy policy describes how we collect, use, and protect your personal information when you use our website. By accessing our site, you agree to the practices described in this policy.
      </p>

      <h2 className="text-2xl font-semibold mb-2">1. Information Collection</h2>
      <p className="mb-6">
        We collect information when you browse our site, subscribe to our newsletter, or interact with our advertisements. The information collected may include your name, email address, IP address, and browsing data.
      </p>

      <h2 className="text-2xl font-semibold mb-2">2. Use of Information</h2>
      <p className="mb-4">
        The information we collect may be used to:
      </p>
      <ul className="list-disc list-inside mb-6">
        <li>Personalize your user experience</li>
        <li>Improve our website</li>
        <li>Send you newsletters if you have subscribed</li>
        <li>Serve relevant ads through Google AdSense</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">3. Cookies</h2>
      <p className="mb-4">
        Our site uses cookies to enhance your online experience. Cookies are small text files stored on your device when you visit our site. They help us understand how you use our site and provide you with tailored content.
      </p>
      <p className="mb-6">
        You can choose to disable cookies through your browser settings, but this may limit your access to certain features of our site.
      </p>

      <h2 className="text-2xl font-semibold mb-2">4. Google AdSense Ads</h2>
      <p className="mb-4">
        Our site displays ads served by Google AdSense. Google may use cookies to show you ads based on your previous visits to our site or other websites.
      </p>
      <p className="mb-6">
        For more information about the cookies used by Google AdSense, you can review the 
        <a href="https://policies.google.com/technologies/ads" className="text-blue-500 underline" target='_blank' rel='noreferrer'>
          Google Privacy Policy</a>.
      </p>

      <h2 className="text-2xl font-semibold mb-2">5. Data Security</h2>
      <p className="mb-6">
        We implement security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.
      </p>

      <h2 className="text-2xl font-semibold mb-2">6. Changes to the Privacy Policy</h2>
      <p className="mb-6">
        We reserve the right to modify this privacy policy at any time. Any changes will be posted on this page and will take effect immediately upon publication. We encourage you to regularly review this page to stay informed about any updates.
      </p>

      <h2 className="text-2xl font-semibold mb-2">7. Contact</h2>
      <p className="mb-6">
        If you have any questions regarding this privacy policy, you can contact us at the following email address: xiliau@proton.me.
      </p>

      <p className="mb-4">
        Effective date: August 31, 2024
      </p>
    </div>
  );
};
