const text_data = {
  "text-menu-title-calcul-literal": {
    "fr": "Calcul litéral",
    "en": "Literal calculation",
  },
  "text-menu-item-expand-expr": {
    "fr": "Développer et réduire une expression",
    "en": "Expand and reduce an expression",
  },
  "text-menu-item-factor-expr": {
    "fr": "Factoriser une expression",
    "en": "Factorize an expression",
  },
  "text-menu-item-verify-eq-expr": {
    "fr": "Vérifier que deux expressions sont égales",
    "en": "Verify that two expressions are equal",
  },
  "text-menu-item-eval-expr": {
    "fr": "Évaluer une expression",
    "en": "Evaluate an expression",
  },

  "text-menu-title-nombre-fraction": {
    "fr": "Nombres et Fractions",
    "en": "Numbers and Fractions",
  },
  "text-menu-item-same-denom": {
    "fr": "Mise sous le même dénominateur",
    "en": "Put under the same denominator",
  },
  "text-menu-item-prime-factors": {
    "fr": "Factorisation en nombres premiers",
    "en": "Prime factorization",
  },
  "text-menu-item-gcd": {
    "fr": "Calculer le PGCD",
    "en": "Calculate the GCD",
  },
  "text-menu-item-lcm": {
    "fr": "Calculer le PPCM",
    "en": "Calculate the LCM",
  },

  "text-path-acceuil": {
    "fr": "Accueil",
    "en": "Home",
  },
  "text-path-matiere": {
    "fr": "Matière",
    "en": "Subject",
  },
  "text-path-maths": {
    "fr": "Mathématiques",
    "en": "Mathematics",
  },
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};
