import React from "react";
import { PageComponent } from "../PageComponent";
import { tr } from "../../../utility/translation";


export const Footer = ({ className }) => {
  return (
    <PageComponent
      className={`flex justify-center py-4 ${className} text-base`}
    >
      xili<span className="text-red-700">A</span>u &ndash; &copy; {new Date().getFullYear()} xili
      <span
        className="text-gray-700 mx-4"
      >|</span>
      {tr(`Le nom de domaine xiliau.com est héberger par Squarespace`)}
      <span
        className="text-gray-700 mx-4"
      >|</span>
      xiliau@proton.me
    </PageComponent>
  );
};
