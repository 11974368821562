const text_data = {
  "text-page-title": {
    "fr": "Mise sous le même dénominateur",
    "en": "Put under the same denominator",
  },
  "text-doc-syms": {
    "fr": "Documentation de la syntaxe et des symboles",
    "en": "Documentation of the syntax and symbols",
  },
  "text-enter-expr": {
    "fr": "Entrez une expression à mettre sous le même dénominateur",
    "en": "Enter an expression to put under the same denominator",
  },
  "text-put-under-same-denominator": {
    "fr": "Mettre sous le même dénominateur",
    "en": "Put under the same denominator",
  },
  "text-copy-result": {
    "fr": "Copier le résultat",
    "en": "Copy the result",
  },
  "text-result-copied": {
    "fr": "Résultat copié",
    "en": "Result copied",
  },
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};
