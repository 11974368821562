const text_data = {
  "text-cookie-consent-message": {
    "fr": "En naviguant sur ce site, vous accepter les conditions générales d'utilisation (C.G.U), l'utilisation de cookies et le traitement de vos données personnelles dans le but d'améliorer votre expérience utilisateur, d'analyser notre trafic, et de vous proposer des contenus personnalisés. Pour en savoir plus et paramétrer les cookies, consultez notre",
    "en": "By browsing this site, you accept the terms of use, the use of cookies and the processing of your personal data in order to improve your user experience, analyze our traffic, and offer you personalized content. To find out more and set cookies, check out our",
  },
  "text-privacy-policy": {
    "fr": "Politique de Confidentialité",
    "en": "Privacy Policy"
  },
  "text-cgu": {
    "fr": "Conditions Générales d'Utilisation",
    "en": "Terms of Service"
  }
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem('lang')];
};
