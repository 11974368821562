import React from "react";

import { DocSymsPageFr } from "./lang/fr";
import { DocSymsPageEn } from "./lang/en";


export const DocSymsPage = () => {
  const lang = localStorage.getItem("lang") || "en";

  return (
    lang === "fr" ?
      <DocSymsPageFr />
    :
      <DocSymsPageEn />
  );
};
