import React from "react";


export const AdvSection = () => {
  return (
    <div className="p-2 min-w-52 w-52">
      <ins
        className="adsbygoogle"
        style={{display: 'inline-block', width:'200px', height:'300px'}}
        data-ad-client="ca-pub-4195860529566641"
        data-ad-slot="3867838339"
      ></ins>
      <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
      </script>

      <ins
        className="adsbygoogle"
        style={{display: 'inline-block', width:'200px', height:'300px'}}
        data-ad-client="ca-pub-4195860529566641"
        data-ad-slot="6083407329"
      ></ins>
      <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
      </script>

      <ins
        className="adsbygoogle"
        style={{display: 'inline-block', width:'200px', height:'300px'}}
        data-ad-client="ca-pub-4195860529566641"
        data-ad-slot="4606674120"
      ></ins>
      <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </div>
  );
};
