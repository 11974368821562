import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import { HomePage } from '../../pages/Home';
import { PrivacyPolicy } from '../../pages/PrivacyPolicy';
import { TermsOfServicePage } from '../../pages/ConditionsUtilisation';


import { SubjectPage } from '../../pages/Subject';


import { MathsPage } from '../../pages/Subject/Maths';
import { DocSymsPage } from '../../pages/Subject/Maths/DocSymsPage';

import { MathsDevReduceExprPage } from '../../pages/Subject/Maths/Seconde/Calcul-Literal/DevReduceExprPage';
import { FactorPage } from '../../pages/Subject/Maths/Seconde/Calcul-Literal/FactorPage';
import { VerifyEqExprPage } from '../../pages/Subject/Maths/Seconde/Calcul-Literal/VerifyEqExprPage';
import { EvalExprPage } from '../../pages/Subject/Maths/Seconde/Calcul-Literal/EvalExprPage';

import { MemeDenominateurPage } from '../../pages/Subject/Maths/Seconde/NombreEtFraction/MemeDenominateurPage';
import { FactoriseNombrePremierPage } from '../../pages/Subject/Maths/Seconde/NombreEtFraction/FactoriseNombrePremierPage';
import { PGCDPage } from '../../pages/Subject/Maths/Seconde/NombreEtFraction/PGCDPage';
import { PPCMPage } from '../../pages/Subject/Maths/Seconde/NombreEtFraction/PPCMPage';


import { FrancaisPage } from '../../pages/Subject/Francais';


export const MainRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route index element={<HomePage />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />


        <Route path="/subject" element={<SubjectPage />} />

        <Route path="/subject/maths" element={<MathsPage />} />

        <Route path="/subject/maths/doc-syms" element={<DocSymsPage />} />
        <Route path="/subject/maths/expand-expr" element={<MathsDevReduceExprPage />} />
        <Route path="/subject/maths/factor-expr" element={<FactorPage />} />
        <Route path="/subject/maths/verify-eq-expr" element={<VerifyEqExprPage />} />
        <Route path="/subject/maths/eval-expr" element={<EvalExprPage />} />

        <Route path="/subject/maths/same-denom" element={<MemeDenominateurPage />} />
        <Route path="/subject/maths/prime-factors" element={<FactoriseNombrePremierPage />} />
        <Route path="/subject/maths/gcd" element={<PGCDPage />} />
        <Route path="/subject/maths/lcm" element={<PPCMPage />} />

        <Route path="/subject/french" element={<FrancaisPage />} />

        <Route path="*" element={<h1>404</h1>} />
      </Routes>
    </Router>
  );
}

export default MainRouter;
