import React from 'react';


export const PrivacyPolicyFr = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Politique de confidentialité</h1>
      <p className="mb-4">
        Dernière mise à jour : 31 août 2024
      </p>
      <p className="mb-6">
        
          Cette politique de confidentialité décrit comment nous collectons, utilisons et protégeons vos informations
          personnelles lorsque vous utilisez notre site web. En accédant à notre site, vous acceptez les pratiques décrites
          dans cette politique.
        
      </p>

      <h2 className="text-2xl font-semibold mb-2">1. Collecte des informations</h2>
      <p className="mb-6">
        
          Nous collectons des informations lorsque vous naviguez sur notre site, vous inscrivez à notre newsletter ou
          interagissez avec nos publicités. Les informations collectées peuvent inclure votre nom, adresse e-mail, adresse
          IP, et des données de navigation.
        
      </p>

      <h2 className="text-2xl font-semibold mb-2">2. Utilisation des informations</h2>
      <p className="mb-4">
        Les informations que nous collectons peuvent être utilisées pour :
      </p>
      <ul className="list-disc list-inside mb-6">
        <li>Personnaliser votre expérience utilisateur</li>
        <li>Améliorer notre site web</li>
        <li>Vous envoyer des newsletters si vous vous êtes inscrit(e)</li>
        <li>Diffuser des publicités pertinentes via Google AdSense</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">3. Cookies</h2>
      <p className="mb-4">
        
          Notre site utilise des cookies pour améliorer votre expérience en ligne. Les cookies sont de petits fichiers
          texte stockés sur votre appareil lorsque vous visitez notre site. Ils nous aident à comprendre comment vous
          utilisez notre site et à vous proposer des contenus adaptés.
        
      </p>
      <p className="mb-6">
        
          Vous pouvez choisir de désactiver les cookies via les paramètres de votre navigateur, mais cela pourrait limiter
          votre accès à certaines fonctionnalités de notre site.
        
      </p>

      <h2 className="text-2xl font-semibold mb-2">4. Publicités Google AdSense</h2>
      <p className="mb-4">
        
          Notre site affiche des publicités diffusées par Google AdSense. Google peut utiliser des cookies pour vous
          proposer des publicités basées sur vos précédentes visites sur notre site ou sur d'autres sites web.
        
      </p>
      <p className="mb-6">
        Pour plus d'informations sur les cookies utilisés par Google AdSense, vous pouvez consulter la 
        <a href="https://policies.google.com/technologies/ads" className="text-blue-500 underline" target='_blank' rel='noreferrer'
        >Politique de confidentialité de Google</a>.
      </p>

      <h2 className="text-2xl font-semibold mb-2">5. Sécurité des données</h2>
      <p className="mb-6">
        
          Nous mettons en œuvre des mesures de sécurité pour protéger vos informations personnelles. Cependant, aucune
          méthode de transmission sur Internet ou de stockage électronique n'est sûre à 100%. Par conséquent, nous ne
          pouvons garantir une sécurité absolue.
        
      </p>

      <h2 className="text-2xl font-semibold mb-2">6. Modifications de la politique de confidentialité</h2>
      <p className="mb-6">
        
          Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification
          sera publiée sur cette page et prendra effet immédiatement après sa publication. Nous vous encourageons à
          consulter régulièrement cette page pour être informé(e) des changements éventuels.
        
      </p>

      <h2 className="text-2xl font-semibold mb-2">7. Contact</h2>
      <p className="mb-6">
        Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter à l'adresse suivante : xiliau@proton.me .
      </p>

      <p className="mb-4">
        Date d'entrée en vigueur : 31 août 2024
      </p>
    </div>
  );
};
