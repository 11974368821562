const tex_data = {
  "text-erreur-de-syntaxe": {
    "fr": "Erreur de syntaxe",
    "en": "Syntax error"
  }
};

export const tr = (key) => {
  const lang = localStorage.getItem("lang") || "en";
  return tex_data[key][lang];
};
