const text_data = {
  "text-equal": {
    "fr": "Les expressions sont égales",
    "en": "The expressions are equal",
  },
  "text-different": {
    "fr": "Les expressions semblent différentes",
    "en": "The expressions seem different",
  },
  "text-page-title": {
    "fr": "Vérifier que deux expressions sont égales",
    "en": "Check that two expressions are equal",
  },
  "text-doc-syms": {
    "fr": "Documentation de la syntaxe et des symboles",
    "en": "Documentation of the syntax and symbols",
  },
  "text-enter-first-expr": {
    "fr": "Entrez la première expression",
    "en": "Enter the first expression",
  },
  "text-enter-second-expr": {
    "fr": "Entrez la deuxième expression",
    "en": "Enter the second expression",
  },
  "text-verify": {
    "fr": "Vérifier",
    "en": "Verify",
  },
  "text-copy-result": {
    "fr": "Copier le résultat",
    "en": "Copy the result",
  },
  "text-result-copied": {
    "fr": "Résultat copié",
    "en": "Result copied",
  },
  "text-the-calculation-can-take-some-time": {
    "fr": "Le calcul peut prendre du temps",
    "en": "The calculation can take some time",
  },
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};
