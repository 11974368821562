import React from 'react';

export const TermsOfServicePageEn = () => {
  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6">Terms of Service (ToS)</h1>

      <p className="text-gray-500 mb-6">Last updated: September 1, 2024</p>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">1. Website Overview</h2>
        <p>
          The website xiliau.com (hereinafter referred to as "the Website") is an amateur site made available to users (hereinafter referred to as "the User") for the purpose described in the "Home" section.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">2. Acceptance of the Terms of Service</h2>
        <p>
          By accessing and using the Website, the User fully and unreservedly accepts these terms of service. If the User does not accept these terms, they are advised not to use the Website.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">3. Access to the Website</h2>
        <p>
          The Website is freely accessible to any User with internet access. All costs related to access to the Website, whether associated with hardware, software, or internet access, are exclusively the responsibility of the User. The Website's publisher cannot be held responsible for any network or server malfunctions or any other event beyond its control that would prevent or degrade access to the Website.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">4. User Behavior</h2>
        <p>
          The User agrees to use the Website responsibly, not infringe on the rights of third parties, and comply with the laws in force. The User is solely responsible for their use of the information, content, and services offered on the Website.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">5. Limitation of Liability</h2>
        <p>
          The Website's publisher cannot be held liable for direct or indirect damages resulting from the use or inability to use the Website. Under no circumstances can the publisher be held responsible for the User's behavior or any damages of any kind, direct or indirect, caused to the User or third parties, due to the use of the information, content, or services available on the Website.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">6. Intellectual Property</h2>
        <p>
          All elements of the Website, including text, images, graphics, logos, videos, sounds, and other content available on the Website, are the property of the publisher or its partners and are protected by intellectual property law. Any reproduction, representation, modification, publication, or adaptation of all or part of the elements of the Website, regardless of the means or process used, is prohibited without prior written authorization.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">7. Hyperlinks</h2>
        <p>
          The Website may contain hyperlinks to other websites. The publisher disclaims any responsibility for the content accessible through these links, which are provided for informational purposes only.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">8. Modification of the Terms of Service</h2>
        <p>
          The publisher reserves the right to modify these terms of service at any time. The User is therefore invited to consult them regularly. By continuing to use the Website after the publication of the changes, the User accepts the new terms.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">9. Governing Law</h2>
        <p>
          These terms are governed by French law. In the event of a dispute related to the use of the Website, the parties will attempt to find an amicable solution. Failing that, the dispute will be brought before the competent courts.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">10. Contact</h2>
        <p>
          For any questions regarding these terms of service or the Website, the User can contact the publisher at the following email address: xiliau@proton.me.
        </p>
      </section>
    </div>
  );
};
