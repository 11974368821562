import React from "react";
import { Link } from "react-router-dom";


export const PathComponent = ({ path, className }) => {
  return (
    <div className={`flex flex-row items-center ${className} text-lg`}>
      <div className="mx-1">:</div>
      {path.map((item, index) => (
        <div key={index} className="flex flex-row items-center">
          <Link to={item.href} className="text-blue-700">
            <span className="font-bold text-blue-700 hover:text-orange-400">{item.title}</span>
          </Link>
          <span className="text-gray-700 mx-0.5"> {'>'} </span>
        </div>
      ))}
    </div>
  );
};
