const text_data = {
  "text-page-title": {
    "fr": "Développer et réduire une expression",
    "en": "Develop and reduce an expression",
  },
  "text-doc-syms": {
    "fr": "Documentation de la syntaxe et des symboles",
    "en": "Documentation of the syntax and symbols",
  },
  "text-enter-expr": {
    "fr": "Entrez une expression à développer et réduire",
    "en": "Enter an expression to develop and reduce",
  },
  "text-expand-reduce": {
    "fr": "Développer et réduire",
    "en": "Develop and reduce",
  },
  "text-copy-result": {
    "fr": "Copier le résultat",
    "en": "Copy the result",
  },
  "text-result-copied": {
    "fr": "Résultat copié",
    "en": "Result copied",
  },
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};
