import React from 'react';

const symbols = [
  {
    category: "Basic Operators",
    items: [
      { symbol: "+", meaning: "Addition", example: "2 + 3" },
      { symbol: "-", meaning: "Subtraction", example: "5 - 2" },
      { symbol: "*", meaning: "Multiplication", example: "4 * 3" },
      { symbol: "/", meaning: "Division", example: "8 / 2" },
      { symbol: "^", meaning: "Exponentiation", example: "2 ^ 3" },
      { symbol: "%", meaning: "Modulo (remainder of division)", example: "7 % 3" },
      { symbol: "()", meaning: "Parentheses to group terms", example: "(2 + 3) * 4" },
    ]
  },
  {
    category: "Mathematical Functions",
    items: [
      { symbol: "sqrt(x)", meaning: "Square root of `x`", example: "sqrt(16)" },
      { symbol: "abs(x)", meaning: "Absolute value of `x`", example: "abs(-5)" },
      { symbol: "log(x)", meaning: "Natural logarithm (base `e`)", example: "log(10)" },
      { symbol: "log10(x)", meaning: "Logarithm base 10", example: "log10(100)" },
      { symbol: "exp(x)", meaning: "Exponential of `x` (`e^x`)", example: "exp(1)" },
      { symbol: "sin(x)", meaning: "Sine of `x` (in radians)", example: "sin(pi / 2)" },
      { symbol: "cos(x)", meaning: "Cosine of `x` (in radians)", example: "cos(0)" },
      { symbol: "tan(x)", meaning: "Tangent of `x` (in radians)", example: "tan(pi / 4)" },
      { symbol: "gcd(a, b)", meaning: "Greatest common divisor of `a` and `b`", example: "gcd(8, 12)" },
    ]
  },
  {
    category: "Statistical Functions",
    items: [
      { symbol: "sum([a, b, c])", meaning: "Sum of the elements", example: "sum([1, 2, 3])" },
      { symbol: "mean([a, b, c])", meaning: "Mean of the elements", example: "mean([1, 2, 3])" },
      { symbol: "median([a, b, c])", meaning: "Median of the elements", example: "median([1, 3, 2])" },
      { symbol: "min([a, b, c])", meaning: "Minimum of the elements", example: "min([1, 2, 3])" },
      { symbol: "max([a, b, c])", meaning: "Maximum of the elements", example: "max([1, 2, 3])" },
    ]
  },
  {
    category: "Calculus Functions",
    items: [
      { symbol: "derivative(f, x)", meaning: "Derivative of `f` with respect to `x`", example: "derivative('x^2', 'x')" },
      { symbol: "integral(f, x)", meaning: "Integral of `f` with respect to `x`", example: "integral('x^2', 'x')" },
      { symbol: "integral(f, x, a, b)", meaning: "Integral of `f` with respect to `x` from `a` to `b`", example: "integral('x^2', 'x', 1, 2)" },
      { symbol: "integral(f, x, a)", meaning: "Integral of `f` with respect to `x` from `a`", example: "integral('x^2', 'x', 1)" },
      { symbol: "diff([a, b, c])", meaning: "Successive differences", example: "diff([1, 2, 4])" },
      { symbol: "factor(x)", meaning: "Factorization of `x`", example: "factor('x^2 - 4')" },
      { symbol: "expand(x)", meaning: "Expansion of `x`", example: "expand('(x - 2) * (x + 2)')" },
    ]
  },
  {
    category: "Mathematical Constants",
    items: [
      { symbol: "pi", meaning: "Constant π", example: "pi" },
      { symbol: "e", meaning: "Constant e (Euler's number)", example: "e" },
      { symbol: "i", meaning: "Imaginary unit", example: "i" },
      { symbol: "Infinity", meaning: "Infinity", example: "Infinity" },
    ]
  },
  {
    category: "Matrix Operations",
    items: [
      { symbol: "matrix([[a, b], [c, d]])", meaning: "Creates a matrix", example: "matrix([[1, 2], [3, 4]])" },
      { symbol: "det(A)", meaning: "Determinant of matrix `A`", example: "det([[1, 2], [3, 4]])" },
      { symbol: "inv(A)", meaning: "Inverse of matrix `A`", example: "inv([[1, 2], [3, 4]])" },
      { symbol: "transpose(A)", meaning: "Transpose of matrix `A`", example: "transpose([[1, 2], [3, 4]])" },
    ]
  },
  {
    category: "Comparison",
    items: [
      { symbol: "==", meaning: "Equal to", example: "2 == 2" },
      { symbol: "!=", meaning: "Not equal to", example: "2 != 3" },
      { symbol: "<", meaning: "Less than", example: "2 < 3" },
      { symbol: "<=", meaning: "Less than or equal to", example: "2 <= 2" },
      { symbol: ">", meaning: "Greater than", example: "3 > 2" },
      { symbol: ">=", meaning: "Greater than or equal to", example: "3 >= 3" },
    ]
  }
];

export const DocSymsPageEn = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-6">Symbol Documentation</h1>
      {symbols.map((section, index) => (
        <div key={index} className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{section.category}</h2>
          <table className="table-auto w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-4 py-2 text-left">Symbol</th>
                <th className="px-4 py-2 text-left">Meaning</th>
                <th className="px-4 py-2 text-left">Example</th>
              </tr>
            </thead>
            <tbody>
              {section.items.map((item, idx) => (
                <tr key={idx} className="border-b">
                  <td className="px-4 py-2">{item.symbol}</td>
                  <td className="px-4 py-2">{item.meaning}</td>
                  <td className="px-4 py-2">{item.example}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};
