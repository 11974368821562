import React from 'react';
import { Page } from '../../components/Page';

import { tr } from "./text";


const SiteDescription = () => {
  return (
    <div className="py-2">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
        {tr('text-main-title')}
      </h1>
      <p className="text-lg text-gray-700 mb-6 px-2 text-justify">
        {tr('text-main-descr')}
      </p>
    </div>
  );
};


export const HomeMenuItems = [/*
  {
    supTitle: tr("Mode"),
    items: [
      {icon: <></>, text: tr("Matière"), href: "/subject"},
      {icon: <></>, text: tr("Niveau"), href: "/level"},
    ]
  },*/
  {
    supTitle: tr("text-left-menu-matiere"),
    items: [
      {icon: <></>, text: tr("text-left-menu-maths"), href: "/subject/maths"},
      {icon: <></>, text: tr("text-left-menu-francais"), href: "/subject/french"},
      //{icon: <></>, text: tr("Anglais"), href: "/subject/english"},
    ]
  },/*
  {
    supTitle: tr("Niveau"),
    items: [
      {icon: <></>, text: tr("Seconde"), href: "/level/second"},
      {icon: <></>, text: tr("Première"), href: "/level/first"},
      {icon: <></>, text: tr("Terminale"), href: "/level/last"},
    ]
  },*/
];


export const HomePathItems = [{title: tr('text-path-accueil'), href: '/'}];


export const HomePage = () => {
  return (
    <Page
      menu={HomeMenuItems}
      path={HomePathItems}
    >
      <SiteDescription />
    </Page>
  );
};
