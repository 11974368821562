import React from "react";
import { Header } from "./Header";
import { Body } from "./Body";
import { Footer } from "./Footer";
import CookieConsent from "react-cookie-consent";
import { tr } from "./text";
import { Link } from "react-router-dom";


export const Page = ({ path, children, menu }) => {
  const [isBodyMenuShow, setIsBodyMenuShow] = React.useState(true);

  return (
    <div className="flex w-full min-h-screen justify-center items-center bg-gradient-to-br from-blue-100 via-orange-50 to-blue-100">
      <CookieConsent
        location="bottom"
        buttonText="Accepter"
        declineButtonText="Refuser"
        cookieName="myAwesomeCookieName"
        style={{ background: "#2B373B" }}
        buttonStyle={{ background: "#4CAF50", color: "#fff", fontSize: "13px" }}
        declineButtonStyle={{ background: "#f44336", color: "#fff", fontSize: "13px" }}
        expires={150}
      >
        {tr('text-cookie-consent-message')}
        {" "}
        <Link to="/privacy-policy" className="underline text-blue-600" target={"_blank"} rel="noreferrer">
          {tr('text-privacy-policy')}
          {" "}
        </Link>
        {tr('text-privacy-policy')}
        {" "}
        <Link to="/terms-of-service" className="underline text-blue-600" target={"_blank"} rel="noreferrer">
          {tr('text-cgu')}
        </Link> {" "}
        .
      </CookieConsent>
      <div className="max-w-screen-xl w-full min-h-screen shadow-2xl shadow-black">
        <Header
          className="border-b border-gray-300"
          onBarsClicked={() => setIsBodyMenuShow(!isBodyMenuShow)}
        />
        <Body
          showBodyMenu={isBodyMenuShow}
          menu={menu}
          path={path}
        >{ children }</Body>
        <Footer
          className="border-t border-gray-300"
        />
      </div>
    </div>
  );
};
