const text_data = {
  "text-menu-title-welcome": {
    "fr": "Bienvenue dans la section mathématiques",
    "en": "Welcome to the mathematics section",
  },
  "text-menu-descr-welcome": {
    "fr": "Bienvenue dans la section de mathématiques générales de notre site. Ici, nous vous offrons un espace dédié à l'exploration et à la résolution de divers problèmes mathématiques. Dans le menu de navigation, vous trouverez plein d'outils spécialement conçus pour vous aider à trouver ou à vérifier les solutions à des problèmes mathématiques spécifiques. Que vous soyez un étudiant cherchant de l'aide pour vos devoirs, un enseignant préparant ses cours, ou simplement un passionné de mathématiques, nos outils vous fourniront les ressources nécessaires pour aborder une variété de concepts mathématiques avec confiance et précision. Explorez librement, et laissez-nous vous accompagner dans votre voyage mathématique.",
    "en": "Welcome to the general mathematics section of our site. Here, we offer you a space dedicated to the exploration and resolution of various mathematical problems. In the navigation menu, you will find many tools specially designed to help you find or verify solutions to specific mathematical problems. Whether you are a student looking for help with your homework, a teacher preparing your lessons, or simply a math enthusiast, our tools will provide you with the necessary resources to confidently and accurately approach a variety of mathematical concepts. Explore freely, and let us accompany you on your mathematical journey.",
  },
  "text-menu-title-index": {
    "fr": "Index :",
    "en": "Index:",
  },
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};
