const text_data = {
  "text-page-title": {
    "fr": "Calculer le PPCM de deux nombres ou expressions",
    "en": "Calculate the LCM of two numbers or expressions",
  },
  "text-doc-syms": {
    "fr": "Documentation de la syntaxe et des symboles",
    "en": "Documentation of the syntax and symbols",
  },
  "text-enter-expr1": {
    "fr": "Entrez le premier nombre ou expression",
    "en": "Enter the first number or expression",
  },
  "text-enter-expr2": {
    "fr": "Entrez le deuxième nombre ou expression",
    "en": "Enter the second number or expression",
  },
  "text-calculate-lcm": {
    "fr": "Calculer le PPCM",
    "en": "Calculate the LCM",
  },
  "text-copy-result": {
    "fr": "Copier le résultat",
    "en": "Copy the result",
  },
  "text-result-copied": {
    "fr": "Résultat copié",
    "en": "Result copied",
  },
  "text-calculation-can-take-time": {
    "fr": "Le calcul peut prendre du temps",
    "en": "The calculation can take time",
  },
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};
