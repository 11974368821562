const text_data = {
  "text-page-title": {
    "fr": "Évaluer une expression",
    "en": "Evaluate an expression",
  },
  "text-doc-syms": {
    "fr": "Documentation de la syntaxe et des symboles",
    "en": "Documentation of the syntax and symbols",
  },
  "text-enter-first-expr": {
    "fr": "Entrez la première expression",
    "en": "Enter the first expression",
  },
  "text-enter-vars": {
    "fr": "Entrez les nombres des variables et leurs valeurs séparés par des points-virgules, ex: x=2; y=3",
    "en": "Enter the names of the variables and their values separated by semicolons, ex: x=2; y=3",
  },
  "text-evaluate": {
    "fr": "Évaluer",
    "en": "Evaluate",
  },
  "text-copy-result": {
    "fr": "Copier le résultat",
    "en": "Copy the result",
  },
  "text-result-copied": {
    "fr": "Résultat copié",
    "en": "Result copied",
  },
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};
