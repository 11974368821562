import React from 'react';


export const TermsOfServicePageFr = () => {
  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6">Conditions Générales d'Utilisation (CGU)</h1>

      <p className="text-gray-500 mb-6">Date de dernière mise à jour : 1er Septembre 2024</p>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">1. Présentation du site</h2>
        <p>
          Le site xiliau.com (ci-après "le Site") est un site amateur mis à disposition des utilisateurs (ci-après "l'Utilisateur") pour le but décrit dans la section "Accueil".
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">2. Acceptation des conditions d'utilisation</h2>
        <p>
          En accédant et en utilisant le Site, l'Utilisateur accepte pleinement et sans réserve les présentes conditions générales d'utilisation. Si l'Utilisateur n'accepte pas ces conditions, il est invité à ne pas utiliser le Site.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">3. Accès au site</h2>
        <p>
          Le Site est accessible gratuitement à tout Utilisateur disposant d'un accès à Internet. Tous les coûts afférents à l'accès au Site, qu'ils soient liés aux matériels, logiciels ou accès à Internet, sont exclusivement à la charge de l'Utilisateur. L'éditeur du Site ne peut être tenu responsable de tout dysfonctionnement du réseau ou des serveurs ou de tout autre événement échappant à son contrôle, qui empêcherait ou dégraderait l'accès au Site.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">4. Comportement de l'utilisateur</h2>
        <p>
          L'Utilisateur s'engage à utiliser le Site de manière responsable, à ne pas porter atteinte aux droits des tiers et à respecter les lois en vigueur. L'Utilisateur est seul responsable de l'usage qu'il fait des informations, contenus et services proposés sur le Site.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">5. Limitation de responsabilité</h2>
        <p>
          L'éditeur du Site ne peut être tenu responsable des dommages directs ou indirects résultant de l'utilisation ou de l'incapacité à utiliser le Site. En aucun cas, l'éditeur ne peut être tenu responsable du comportement de l'Utilisateur, ni des dommages de toute nature, directs ou indirects, causés à l'Utilisateur ou à des tiers, du fait de l'utilisation des informations, contenus ou services disponibles sur le Site.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">6. Propriété intellectuelle</h2>
        <p>
          Tous les éléments du Site, y compris les textes, images, graphismes, logos, vidéos, sons, et autres contenus présents sur le Site, sont la propriété de l'éditeur ou de ses partenaires, et sont protégés par le droit de la propriété intellectuelle. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du Site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">7. Liens hypertextes</h2>
        <p>
          Le Site peut contenir des liens hypertextes vers d'autres sites web. L'éditeur décline toute responsabilité quant aux contenus accessibles via ces liens, lesquels sont fournis uniquement à titre d'information.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">8. Modification des conditions d'utilisation</h2>
        <p>
          L'éditeur se réserve le droit de modifier à tout moment les présentes conditions d'utilisation. L'Utilisateur est donc invité à les consulter régulièrement. En continuant à utiliser le Site après la publication des modifications, l'Utilisateur accepte les nouvelles conditions.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">9. Droit applicable</h2>
        <p>
          Les présentes conditions sont régies par le droit français. En cas de litige relatif à l'utilisation du Site, les parties s'efforceront de trouver une solution amiable. À défaut, le litige sera porté devant les juridictions compétentes.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">10. Contact</h2>
        <p>
          Pour toute question relative aux présentes conditions générales d'utilisation ou au Site, l'Utilisateur peut contacter l'éditeur à l'adresse suivante : xiliau@proton.me .
        </p>
      </section>
    </div>
  );
};
