const text_data = {
  "text-left-menu-menu": {
    "fr": "Menu",
    "en": "Menu",
  },
  "text-left-menu-matiere": {
    "fr": "Matière",
    "en": "Subject",
  },
  "text-left-menu-maths": {
    "fr": "Mathématiques",
    "en": "Maths",
  },
  "text-left-menu-francais": {
    "fr": "Français",
    "en": "French",
  },

  "text-path-accueil": {
    "fr": "Accueil",
    "en": "Home",
  },

  "text-main-title": {
    "fr": "Bienvenue sur xiliAu",
    "en": "Welcome to xiliAu",
  },

  "text-main-descr": {
    "fr": "Un site simple proposant des outils et du contenu varié pour vous faciliter la vie. Accessible à tous, avec l'objectif de vous offrir des ressources pratiques et faciles d'utilisation.",
    "en": "A simple site offering tools and varied content to make your life easier. Accessible to all, with the goal of offering you practical and easy-to-use resources.",
  },
};

export const tr = (key) => {
  return text_data[key][localStorage.getItem("lang") || "en"];
};
