import React from "react";

import { TermsOfServicePageFr } from "./lang/fr";
import { TermsOfServicePageEn } from "./lang/en";


export const TermsOfServicePage = () => {
  const lang = localStorage.getItem("lang") || "en";

  return (
    lang === "fr" ?
      <TermsOfServicePageFr />
    :
      <TermsOfServicePageEn />
  );
};
