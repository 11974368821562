import React from 'react';
import { tr } from './text';
import { Page } from '../../../../components/Page';


export const MathsMenuItemsSeconde = [/*
  {
    supTitle: tr("Calcul litéral"),
    items: [
      {icon: <></>, text: tr("Développer et réduire une expression"), href: "/subject/maths/expand-expr"},
    ]
  },*/
];


export const MathsMenuItemsPremiere = [
];


export const MathsMenuItemsTerminale = [
];


export const MathsMenuItems = [...MathsMenuItemsSeconde, ...MathsMenuItemsPremiere, ...MathsMenuItemsTerminale];


export const MathsPathItems = [
  {title: tr('text-path-accueil'), href: '/'},
  {title: tr('text-path-matiere'), href: '/subject'},
  {title: tr('text-path-francais'), href: '/subject/french'}
];


export const MathsUndefinedPage = ({ children }) => {
  return (
    <Page
      menu={MathsMenuItems}
      path={MathsPathItems}
    >{children}</Page>
  );
};
